<template>
  <div id="orders">
    <Loading v-if="orders.length < 1 && loading" />

    <div class="no-orders" v-else-if="!loading && orders.length < 1">
      <img :src="require('@/assets/Lock.png')" />
      <p>{{ $t('lock.orders') }}</p>
    </div>

    <div class="visible" v-else>
      <div class="title">{{ $t('order.active') }}</div>
      <OrderCard
        v-for="order in activeOrders"
        :key="order.orderId"
        :srcOrder="order"
        state="compact"
        :class="{ 'mt-2': order == activeOrders[0] }"
      />
      <div class="empty" v-if="activeOrders.length <= 0">Нет заказов</div>
      <hr />
      <div class="title">{{ $t('order.past') }}</div>
      <OrderCard
        v-for="order in pastOrders"
        :key="order.orderId"
        :srcOrder="order"
        state="compact"
        :class="{ 'mt-2': order == pastOrders[0] }"
      />
      <div class="empty" v-if="pastOrders.length <= 0">Нет заказов</div>

      <div class="loading-wrapper" v-if="loading">
        <md-progress-spinner
          md-mode="indeterminate"
          :md-diameter="30"
          :md-stroke="3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OrderCard from '@/components/OrderCard'

export default {
  components: { OrderCard },
  data() {
    return {
      orders: [],
      loading: true,
      activeOrdersTypes: ['pending', 'in_progress', 'awaiting_payment'],
      limit: 10,
      offset: 0,
      canLoadMoreOrders: true,
    }
  },
  mounted() {
    this.loadMoreOrders()
  },
  methods: {
    loadMoreOrders() {
      this.loading = true
      this.$api
        .get(
          `user/orders?sort=createdAt&limit=${this.limit}&offset=${this.offset}`
        )
        .then(res => {
          if (res.data.data) {
            this.orders.push(...res.data.data)
            this.offset += res.data.data.length
          } else {
            this.canLoadMoreOrders = false
          }
        })
        .finally(() => (this.loading = false))
    },
    _scroll() {
      if (this.loading) return
      const scrolledToBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 500

      if (scrolledToBottom && this.canLoadMoreOrders) this.loadMoreOrders()
    },
  },
  computed: {
    activeOrders() {
      return this.orders.filter(order => {
        return this.activeOrdersTypes.includes(order.status.toLowerCase())
      })
    },
    pastOrders() {
      return this.orders.filter(order => {
        return !this.activeOrdersTypes.includes(order.status.toLowerCase())
      })
    },
  },
}
</script>

<style lang="scss">
#orders {
  margin: 0 auto;
  max-width: 500px;
  padding-bottom: 15px;

  .no-orders {
    color: #7a7a7a;
    font-size: 16px;
    text-align: center;

    img {
      opacity: 0.5;
      width: 100px;
      margin-bottom: 10px;
    }
  }

  > .visible {
    margin: 0 5px 0 5px;

    > .title {
      color: #4c7cc5;
      font-size: 17px;
    }
    > .empty {
      margin-left: 2px;
      font-size: 15px;
    }

    > .loading-wrapper {
      margin: 30px 0;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
